// Autogenerated using mud system-types
import BulkSetStateSystem from "../abi/BulkSetStateSystem.json";
import CombatSystem from "../abi/CombatSystem.json";
import ComponentDevSystem from "../abi/ComponentDevSystem.json";
import DropInventorySystem from "../abi/DropInventorySystem.json";
import EscapePortalSystem from "../abi/EscapePortalSystem.json";
import FactorySystem from "../abi/FactorySystem.json";
import GameConfigSystem from "../abi/GameConfigSystem.json";
import GatherResourceSystem from "../abi/GatherResourceSystem.json";
import Init1System from "../abi/Init1System.json";
import Init2System from "../abi/Init2System.json";
import MoveSystem from "../abi/MoveSystem.json";
import PlayerJoinSystem from "../abi/PlayerJoinSystem.json";
import PortalSystem from "../abi/PortalSystem.json";
import PrototypeDevSystem from "../abi/PrototypeDevSystem.json";
import RangedCombatSystem from "../abi/RangedCombatSystem.json";
import SpawnGoldDevSystem from "../abi/SpawnGoldDevSystem.json";
import TransferInventorySystem from "../abi/TransferInventorySystem.json";

export const SystemAbis = {
  "mudwar.system.BulkSetState": BulkSetStateSystem.abi,
  "mudwar.system.Combat": CombatSystem.abi,
  "mudwar.system.ComponentDev": ComponentDevSystem.abi,
  "mudwar.system.DropInventory": DropInventorySystem.abi,
  "mudwar.system.EscapePortal": EscapePortalSystem.abi,
  "mudwar.system.Factory": FactorySystem.abi,
  "mudwar.system.GameConfig": GameConfigSystem.abi,
  "mudwar.system.GatherResource": GatherResourceSystem.abi,
  "mudwar.system.Init1": Init1System.abi,
  "mudwar.system.Init2": Init2System.abi,
  "mudwar.system.Move": MoveSystem.abi,
  "mudwar.system.PlayerJoin": PlayerJoinSystem.abi,
  "mudwar.system.Portal": PortalSystem.abi,
  "mudwar.system.PrototypeDev": PrototypeDevSystem.abi,
  "mudwar.system.RangedCombat": RangedCombatSystem.abi,
  "mudwar.system.SpawnGoldDev": SpawnGoldDevSystem.abi,
  "mudwar.system.TransferInventory": TransferInventorySystem.abi,
};
