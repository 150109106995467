import { tileCoordToPixelCoord } from "@latticexyz/phaserx";
import { defineSystem, getComponentValue, getComponentValueStrict, Has, ProxyRead, UpdateType } from "@latticexyz/recs";
import { PhaserLayer } from "../../types";

export function createDrawInventorySystem(layer: PhaserLayer) {
  const {
    world,
    parentLayers: {
      network: {
        components: { Inventory, ItemType, OwnedBy },
      },
      local: {
        components: { LocalPosition },
      },
    },
    scenes: {
      Main: {
        objectPool,
        maps: {
          Main: { tileWidth, tileHeight },
        },
      },
    },
  } = layer;

  defineSystem(
    world,
    [Has(ItemType), ProxyRead(OwnedBy, 1), Has(Inventory), Has(LocalPosition)],
    ({ entity, type }) => {
      const embodiedId = `${entity}-item-display`;
      if (type === UpdateType.Exit) objectPool.remove(embodiedId);

      const owner = getComponentValue(OwnedBy, entity)?.value;
      if (!owner) return;
      const ownerIndex = world.entityToIndex.get(owner);
      if (ownerIndex == null) return;

      const position = getComponentValueStrict(LocalPosition, ownerIndex);

      const embodiedObject = objectPool.get(embodiedId, "Rectangle");
      embodiedObject.setComponent({
        id: "rectangle",
        once: (rect) => {
          const pixelCoord = tileCoordToPixelCoord(position, tileWidth, tileHeight);

          rect.height = 3;
          rect.width = 3;
          rect.setFillStyle(0xffff00);
          rect.setPosition(pixelCoord.x, pixelCoord.y + 3);
          rect.setDepth(10);
        },
      });
    }
  );
}
