import { PhaserLayer } from "../../types";
import { pixelToWorldCoord } from "../../utils";
import { map } from "rxjs";
import {
  EntityID,
  EntityIndex,
  getComponentValue,
  getComponentValueStrict,
  Has,
  hasComponent,
  HasValue,
  Not,
  runQuery,
  setComponent,
} from "@latticexyz/recs";
import { WorldCoord } from "../../../../../types";
import { getPlayerEntity, isOwnedByCaller } from "@latticexyz/std-client";
import { Key } from "@latticexyz/phaserx";

export function createInputSystem(layer: PhaserLayer) {
  const {
    scenes: {
      Main: { input, camera, maps },
    },
    components: { HoverHighlight, HoverIcon },
    api: { highlightCoord },
    parentLayers: {
      network: {
        world,
        components: { Factory, TerrainType, OwnedBy, Inventory, Player, Portal },
        api: {
          buildAt,
          dropInventory,
          gatherResource,
          transferInventory,
          escapePortal,
          teleport,
          rangedAttack,
          dev: { spawnGold },
        },
        network: { connectedAddress },
      },
      headless: {
        api: {
          moveEntity,
          attackEntity,
          canGatherResource,
          canTakeInventory,
          canGiveInventory,
          canAttack,
          canRangedAttack,
          canEscapePortal,
          canTeleport,
        },
      },
      local: {
        singletonEntity,
        components: { Selected, LocalPosition, PotentialPath },
      },
    },
  } = layer;

  const { phaserCamera } = camera;
  input.onKeyPress(
    (keys) => keys.has("UP"),
    () => {
      phaserCamera.setScroll(phaserCamera.scrollX, phaserCamera.scrollY - 20);
    }
  );
  input.onKeyPress(
    (keys) => keys.has("DOWN"),
    () => {
      phaserCamera.setScroll(phaserCamera.scrollX, phaserCamera.scrollY + 20);
    }
  );
  input.onKeyPress(
    (keys) => keys.has("LEFT"),
    () => {
      phaserCamera.setScroll(phaserCamera.scrollX - 20, phaserCamera.scrollY);
    }
  );
  input.onKeyPress(
    (keys) => keys.has("RIGHT"),
    () => {
      phaserCamera.setScroll(phaserCamera.scrollX + 20, phaserCamera.scrollY);
    }
  );

  const getSelectedEntity = () => [...runQuery([Has(Selected)])][0];
  const getHighlightedEntity = () => {
    const hoverHighlight = getComponentValueStrict(HoverHighlight, singletonEntity);
    const highlightedEntity = [
      ...runQuery([HasValue(LocalPosition, { x: hoverHighlight.x, y: hoverHighlight.y }), Not(TerrainType)]),
    ][0];

    return highlightedEntity;
  };
  const getHoverPosition = () => {
    const hoverHighlight = getComponentValue(HoverHighlight, singletonEntity);
    if (!hoverHighlight) return;
    if (!hoverHighlight.x || !hoverHighlight.y) return;

    return {
      x: hoverHighlight.x,
      y: hoverHighlight.y,
    };
  };

  const canMoveTo = (entity: EntityIndex, targetPosition: WorldCoord) => {
    const paths = getComponentValue(PotentialPath, entity);
    if (!paths || paths.x.length === 0) {
      return false;
    }

    for (let i = 0; i < paths.x.length; i++) {
      if (paths.x[i] == targetPosition.x && paths.y[i] == targetPosition.y) {
        return true;
      }
    }
    return false;
  };

  const onRightClick = function (clickedPosition: WorldCoord) {
    const playerEntity = getPlayerEntity(connectedAddress.get(), world, Player);
    if (playerEntity == null) return;

    const selectedEntity = getSelectedEntity();
    if (selectedEntity == null) return;
    if (!isOwnedByCaller(OwnedBy, selectedEntity, playerEntity, world.entityToIndex)) return;

    const highlightedEntity = getHighlightedEntity();

    if (highlightedEntity != null) {
      if (canTeleport(selectedEntity, highlightedEntity)) {
        const portal = getComponentValueStrict(Portal, highlightedEntity);
        teleport(world.entities[highlightedEntity], portal.targetIds[0] as EntityID, world.entities[selectedEntity]);
        return;
      } else if (canEscapePortal(selectedEntity, highlightedEntity)) {
        escapePortal(world.entities[selectedEntity], world.entities[highlightedEntity]);
        return;
      } else if (canGatherResource(highlightedEntity, selectedEntity)) {
        gatherResource(world.entities[highlightedEntity], world.entities[selectedEntity]);
        return;
      } else if (canTakeInventory(highlightedEntity, selectedEntity)) {
        transferInventory(world.entities[highlightedEntity], world.entities[selectedEntity]);
        return;
      } else if (canRangedAttack(selectedEntity, highlightedEntity)) {
        rangedAttack(world.entities[selectedEntity], world.entities[highlightedEntity]);
        return;
      } else if (canAttack(selectedEntity, highlightedEntity)) {
        attackEntity(selectedEntity, highlightedEntity);
        return;
      }

      if (isOwnedByCaller(OwnedBy, highlightedEntity, playerEntity, world.entityToIndex)) {
        if (canGiveInventory(selectedEntity, highlightedEntity)) {
          transferInventory(world.entities[selectedEntity], world.entities[highlightedEntity]);
          return;
        }
      }
    }

    moveEntity(selectedEntity, clickedPosition);
  };

  const hoverUI = function (hoveredPosition: WorldCoord) {
    const previousHoveredPosition = getComponentValue(HoverHighlight, singletonEntity);
    if (
      previousHoveredPosition &&
      hoveredPosition.x === previousHoveredPosition.x &&
      hoveredPosition.y === previousHoveredPosition.y
    ) {
      return;
    }

    highlightCoord(hoveredPosition);
    const selectedEntity = getSelectedEntity();
    if (!selectedEntity) {
      setComponent(HoverIcon, singletonEntity, { icon: "default" });
      return;
    }

    const hoverHighlight = getComponentValueStrict(HoverHighlight, singletonEntity);
    const highlightedEntity = [
      ...runQuery([HasValue(LocalPosition, { x: hoverHighlight.x, y: hoverHighlight.y }), Not(TerrainType)]),
    ][0];
    if (selectedEntity == highlightedEntity) {
      setComponent(HoverIcon, singletonEntity, { icon: "default" });
      return;
    }

    const playerEntity = world.entityToIndex.get(connectedAddress.get() as EntityID);

    if (!playerEntity) return;
    if (!hasComponent(Player, playerEntity)) return;
    if (!isOwnedByCaller(OwnedBy, selectedEntity, playerEntity, world.entityToIndex)) return;

    if (highlightedEntity != null) {
      if (canTeleport(selectedEntity, highlightedEntity)) {
        setComponent(HoverIcon, singletonEntity, { icon: "url(assets/transfer.png), pointer" });
        return;
      } else if (canEscapePortal(selectedEntity, highlightedEntity)) {
        setComponent(HoverIcon, singletonEntity, { icon: "url(assets/move.png), pointer" });
        return;
      } else if (canGatherResource(highlightedEntity, selectedEntity)) {
        setComponent(HoverIcon, singletonEntity, { icon: "url(assets/pickup.png), pointer" });
        return;
      } else if (canTakeInventory(highlightedEntity, selectedEntity)) {
        setComponent(HoverIcon, singletonEntity, { icon: "url(assets/pickup.png), pointer" });
        return;
      } else if (canRangedAttack(selectedEntity, highlightedEntity)) {
        setComponent(HoverIcon, singletonEntity, { icon: "url(assets/attack.png), pointer" });
        return;
      } else if (canAttack(selectedEntity, highlightedEntity)) {
        setComponent(HoverIcon, singletonEntity, { icon: "url(assets/attack.png), pointer" });
        return;
      }

      if (isOwnedByCaller(OwnedBy, highlightedEntity, playerEntity, world.entityToIndex)) {
        if (canGiveInventory(selectedEntity, highlightedEntity)) {
          setComponent(HoverIcon, singletonEntity, { icon: "url(assets/transfer.png), pointer" });
          return;
        }
      }
    }

    if (canMoveTo(selectedEntity, hoveredPosition)) {
      setComponent(HoverIcon, singletonEntity, { icon: "url(assets/move.png), pointer" });
      return;
    }

    setComponent(HoverIcon, singletonEntity, { icon: "default" });
  };

  const NumberKeyNames = ["ONE", "TWO", "THREE", "FOUR", "FIVE"];
  for (let i = 0; i < 5; i++) {
    input.onKeyPress(
      (keys) => keys.has(NumberKeyNames[i] as Key),
      () => {
        const buildPosition = getHoverPosition();
        if (!buildPosition) return;

        const selectedEntity = getSelectedEntity();
        if (!selectedEntity) return;

        const factory = getComponentValue(Factory, selectedEntity);
        if (!factory) return;
        const prototypeId = factory.prototypeIds[i];
        if (!prototypeId) return;

        buildAt(world.entities[selectedEntity], prototypeId, buildPosition);
      }
    );
  }

  input.onKeyPress(
    (keys) => keys.has("A"),
    () => {
      const selectedEntity = getSelectedEntity();
      if (!selectedEntity) return;

      const highlightedEntity = getHighlightedEntity();
      if (!highlightedEntity) return;

      attackEntity(selectedEntity, highlightedEntity);
    }
  );

  input.onKeyPress(
    (keys) => keys.has("G"),
    () => {
      const position = getHoverPosition();
      if (!position) return;

      spawnGold(position);
    }
  );

  input.onKeyPress(
    (keys) => keys.has("P"),
    () => {
      const selectedEntity = getSelectedEntity();
      if (!selectedEntity) return;
      const selectedEntityId = world.entities[selectedEntity];

      const highlightedEntity = getHighlightedEntity();
      if (!highlightedEntity) return;
      const highlightedEntityId = world.entities[highlightedEntity];

      const portal = getComponentValue(Portal, highlightedEntity);
      if (!portal) return;

      const targetId = portal.targetIds[0];

      teleport(highlightedEntityId, targetId as EntityID, selectedEntityId);
    }
  );

  input.onKeyPress(
    (keys) => keys.has("D"),
    () => {
      const selectedEntity = getSelectedEntity();
      if (!selectedEntity) return;

      const hoverPosition = getHoverPosition();
      if (!hoverPosition) return;

      const hasInventory = getComponentValue(Inventory, selectedEntity);
      if (!hasInventory) return;

      dropInventory(world.entities[selectedEntity], hoverPosition);
    }
  );

  input.pointermove$
    .pipe(
      map((pointer) => ({ x: pointer.worldX, y: pointer.worldY })), // Map pointer to pointer pixel cood
      map((pixel) => pixelToWorldCoord(maps.Main, pixel)) // Map pixel coord to tile coord
    )
    .subscribe((coord) => {
      hoverUI(coord);
    });

  input.rightClick$
    .pipe(
      map((pointer) => ({ x: pointer.worldX, y: pointer.worldY })),
      map((pixel) => pixelToWorldCoord(maps.Main, pixel))
    )
    .subscribe((coord) => {
      onRightClick(coord);
    });

  return { onRightClick };
}
